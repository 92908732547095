let zxcvbn = null;

const userEmailTokens = () => {
  const email = $("#user_email").val();

  return email ? [email, ...email.split(/[^\w]|_/)] : [];
};

const userPassword = () => $("#user_password").val();

const watchEmail = () =>
  $("#user_email").keyup((_event) => {
    if (userPassword() === "") return;

    evaluatePassword();
  });

const watchPassword = () =>
  $("#user_password").keyup((_event) => {
    evaluatePassword();

    if (userPassword() === "") $(".progress-bar").hide();
    if (userPassword() !== "") $(".progress-bar").show();
  });

const initPasswordStrengthMeter = () => {
  $("#password__container").append('<div class="progress-bar" />');

  $(".progress-bar")
    .append('<div class="pw-strength" />')
    .append('<div class="separator" style="left: 25%" />')
    .append('<div class="separator" style="left: 50%" />')
    .append('<div class="separator" style="left: 75%" />');

  $(".progress-bar").hide();

  watchEmail();
  watchPassword();
};

const evaluatePassword = () => {
  if (!zxcvbn) {
    import("zxcvbn").then((zxcvbnImport) => {
      zxcvbn = zxcvbnImport.default;
      $(".pw-strength").attr("value", zxcvbn(userPassword(), userEmailTokens()).score);
    });
    return;
  }
  $(".pw-strength").attr("value", zxcvbn(userPassword(), userEmailTokens()).score);
};

export default initPasswordStrengthMeter;
