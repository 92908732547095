/* eslint no-console:0 */

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import { init } from "@sentry/browser";
import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import ReactRailsUJS from "react_ujs";
import "react-dates/initialize";
import jQuery from "jquery";
import Foundation from "foundation-sites";
import initPasswordStrengthMeter from "@elements/PasswordStrengthMeter";

import "stylesheets/v1/application";

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

init({
  dsn: window.sentry_dsn
});

Rails.start();

ActiveStorage.start();
ReactRailsUJS.detectEvents();
ReactRailsUJS.handleEvent("turbo:load", ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent("turbo:before-render", ReactRailsUJS.handleUnmount);

window.rails = Rails
window.$ = jQuery;
window.jQuery = jQuery;

jQuery(document).on("turbo:load", () => {
  initBehaviors();
});

const LABEL_FOCUS_CLASS = "focus";
const LABEL_ERROR_CLASS = "error";
const VISIBILITY_CLASS = "is-visible";

const initForms = () => {
  // // Field Focus/Blur behavior
  jQuery("input, textarea")
    .on("focus", (event) => {
      const element = jQuery(event.target);
      const label = element.closest(".input-field");

      label.addClass(LABEL_FOCUS_CLASS);
    })
    .on("blur", (event) => {
      const element = jQuery(event.target);
      const label = element.closest(".input-field");

      label.removeClass(LABEL_FOCUS_CLASS);
    });

  // Validation fun
  jQuery(document)
    .on("invalid.zf.abide", (event, element) => {
      const container = jQuery(element).closest(".input-field");
      const errorMessage = container.next(".form-error");

      container.addClass(LABEL_ERROR_CLASS);
      errorMessage.addClass(VISIBILITY_CLASS);
    })
    .on("valid.zf.abide", (event, element) => {
      const container = jQuery(element).closest(".input-field");
      const errorMessage = container.next(".form-error");

      container.removeClass(LABEL_ERROR_CLASS);
      errorMessage.removeClass(VISIBILITY_CLASS);
    });
};

const quantityValidator = ($el) => {
  const inputQuantity = parseInt($el.val().replace(/,/g, ""));
  const inputMinRequirement = $el.attr("min");
  const inputMaxRequirement = $el.attr("max");

  const inputIsGreaterThanMin =
    inputMinRequirement !== undefined ? inputQuantity >= inputMinRequirement : true;
  const inputIsLessThanMax =
    inputMaxRequirement !== undefined ? inputQuantity <= inputMaxRequirement : true;

  return inputIsGreaterThanMin && inputIsLessThanMax;
};

const minAgeValidator = ($el) => {
  const age = $el.val();
  const maxAge = $("input[name='max_age']").val();

  return age >= 18;
};

const maxAgeValidator = ($el) => {
  const age = $el.val();
  const minAge = $("input[name='min_age']").val();

  return age <= 99;
};

const initBehaviors = () => {
  Foundation.Abide.defaults.validators.maxAge = maxAgeValidator;
  Foundation.Abide.defaults.validators.minAge = minAgeValidator;
  Foundation.Abide.defaults.validators.quantity = quantityValidator;

  // Foundation JS
  jQuery(document).foundation();

  // Forms
  initForms();

  // Selectable table rows
  jQuery(".select_row").click((event) => {
    event.preventDefault();

    const link = jQuery(event.target).closest("a");
    const row = link.closest("tr");
    const table = row.closest("table");
    const selectedValue = link.data("value");
    const targetFormField = jQuery(link.data("target_selector"));

    table.find("tr").removeClass("selected");
    row.addClass("selected");

    targetFormField.val(selectedValue);
  });

  // External form submit buttons
  jQuery("#submit_trigger").click((event) => {
    event.preventDefault();

    const form = jQuery("#submit_target");
    form.find(":submit").click();
  });

  // Trigger form submissions onClick
  jQuery("[data-submit-form]").click((event) => {
    event.preventDefault();
    jQuery(event.target).closest("form").submit();
  });

  // Clickable Table Rows
  jQuery(".clickable-rows tbody tr").click((event) => {
    event.preventDefault();
    const link = jQuery(event.target).closest("tr").find("a");
    const url = link.attr("href");

    if (!url) {
      link.click();
      return true;
    }

    if (event.ctrlKey || event.shiftKey || event.metaKey) {
      window.open(url, event.shiftKey ? "_blank" : undefined);
    } else {
      window.location = url;
    }
    return true;
  });

  // Selectable Table Rows
  jQuery(".selectable-rows tbody tr").click((event) => {
    const radioButton = jQuery(event.target).closest("tr").find("input[type=radio]");

    if (radioButton && !radioButton.prop("disabled")) {
      radioButton.prop("checked", true);
      radioButton.change();
    }

    return true;
  });

  jQuery(".grid-container-x-scroll").on("scroll", function () {
    if (this.scrollLeft == 0) {
      $(".campaign-row__sticky-column").removeClass("campaign-row__shadow-table");
    } else {
      $(".campaign-row__sticky-column").addClass("campaign-row__shadow-table");
    }
  });

  // Display a web alert message when "a[data-with-alert-message]" element is clicked;
  // Alert message's content is passed through `data-message` datta attr.
  jQuery("a[data-with-alert-message]").on("click", (event) => {
    const message = $(event.target).data("with-alert-message");

    /*eslint no-alert: "off"*/
    alert(message);
  });

  jQuery("a[data-with-form-submission]").on("click", (event) => {
    const form = jQuery(event.target).closest("form");

    form.submit();
  });

  jQuery("input[data-with-form-submission], select[data-with-form-submission]").on(
    "change",
    (event) => {
      const { form } = event.target;

      if (form.checkValidity()) {
        // Check validation and submit form if valid
        form.submit();
      } else {
        // otherwise trigger submit button's click to display HTML validations
        const submitButton = $(form).find('input[type="submit"]');

        submitButton.click();
      }
    }
  );

  //Runs PasswordStrengthMeter jquery
  initPasswordStrengthMeter();

  setTimeout(() => {
    $(".fade").fadeOut();
  }, 3000);
};

// Initialize Stimulus
const application = Application.start();
const context = require.context("@controllers", true, /.js$/);
application.load(definitionsFromContext(context));

// Support component names relative to this directory:
const componentRequireContext = require.context("@components", true);
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener("turbo:request-start", (event) => {
  const { xhr } = event.data;
  xhr.setRequestHeader("X-Turbolinks-Nonce", $("meta[name='csp-nonce']").prop("content"));
});

document.addEventListener("turbo:before-cache", () => {
  $("script[nonce]").each((index, element) => {
    $(element).attr("nonce", element.nonce);
  });
});
